.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #ffffff;
  opacity: 0.5;
  z-index: 9;
}
.loader {
  height: 40px;
  text-align: center;
  top: 50%;
  position: relative;
  background: none;
}
.loader .loader-bar {
  display: inline-block;
  position: relative;
  width: 9px;
  height: 9px;
  margin: 16px 4px;
  border-radius: 50%;
  -webkit-animation: loader-animation 1s ease-in-out infinite;
  animation: loader-animation 1s ease-in-out infinite;
}
.loader.loader-small {
  height: 20px;
}
.loader.loader-small .loader-bar {
  width: 6px;
  height: 6px;
  margin: 7px 2px;
}
.loader.loader-large {
  height: 70px;
}
.loader.loader-large .loader-bar {
  width: 18px;
  height: 18px;
  margin: 25px 4px;
}
.loader .loader-bar:nth-child(1) {
  background-color: #eb1313;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.loader .loader-bar:nth-child(2) {
  background-color: #f5691d;
  -webkit-animation-delay: 0.09s;
  animation-delay: 0.09s;
}
.loader .loader-bar:nth-child(3) {
  background-color: #52ba2b;
  -webkit-animation-delay: 0.18s;
  animation-delay: 0.18s;
}
.loader .loader-bar:nth-child(4) {
  background-color: #12b19f;
  -webkit-animation-delay: 0.27s;
  animation-delay: 0.27s;
}
@-webkit-keyframes loader-animation {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
  }
  20% {
    -webkit-transform: scale(1.8, 1.8) translate(0, -3px);
    transform: scale(1.8, 1.8) translate(0, -3px);
  }
  40% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
  }
}
@keyframes loader-animation {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
  }
  20% {
    -webkit-transform: scale(1.8, 1.8) translate(0, -3px);
    transform: scale(1.8, 1.8) translate(0, -3px);
  }
  40% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
  }
}
