input[type="radio"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 18px;
    height: 18px;
    padding: 4px;
    /* background-color only for content */
    background-clip: content-box;
    border: 2px solid #141497;
    background-color: white;
    border-radius: 50%;
}

/* appearance for checked radiobutton */
input[type="radio"]:checked {
    background-color: #141497;
}

.select-dropdown {
    padding: 0px;

}

.select-dropdown>div {
    background-color: #E6E9F1 !important;
    height: 100% !important;
}

.ant-select-item-option-content {
    border-bottom: 1px solid #E4E6EB;
    padding-bottom: 10px;
}

.ant-select-selection-item {
    padding-top: 3px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
