.ant-drawer-title {
  color: #141497;
  font-size: 14px;
  font-weight: 400;
  padding-top: 3px;
  font-family: "Open Sans", sans-serif;
}
.anticon svg {
  width: 25px;
  height: 20px;
  color: #141497;
}
.ant-drawer-close {
  margin-right: 5px;
}
.ant-drawer-body {
  padding: 25px 20px;
}
.ant-drawer-header {
  padding: 16px 20px;
  border-bottom: 1px solid #bac2d0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.24);
}
