#create-back-arrow-icon > svg {
  width: 7px;
  height: 14px;
}

#create-close-icon > svg {
  width: 15px;
  float: right;
}
.draft-status {
  background: #F1F4F4;
  border: 1px solid #677274;
  padding: 1px 7px 2px;
  box-sizing: border-box;
  border-radius: 100px;
}

footer {
  padding: 13px 20px;
}

.data-type-form input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 18px;
  height: 18px;
  padding: 4px;
  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid #141497;
  background-color: white;
  border-radius: 50%;
  opacity: 1;
}

/* appearance for checked radiobutton */
.data-type-form input[type="radio"]:checked {
  background-color: #141497;
}

.data-type-form input[type="checkbox"] {
  accent-color: #141497;
  width: 16px;
  height: 16px;
}

.data-type-form span.ant-radio-inner {
  opacity: 0;
}

.save-and-close-button, .preview-button {
  font-size: 16px;
  height: 38px;
  line-height: 16px;
}

.datatype-details-footer {
  box-shadow: 0px -1px 0px #BFBFBF;
}

.datatype-details-close-button {
  height: 22px;
  margin-bottom: 12px;
}

input[type="text"] {
  background: #E6E9F1;
}

.select-dropdown {
  padding: 0px;
  
}

.select-dropdown > div {
  background-color: #E6E9F1 !important;
  height: 100% !important;
}

.ant-select-item-option-content {
  border-bottom: 1px solid #E4E6EB;
  padding-bottom: 10px;
}

.input-fields-official .ant-select-selection-item {
  padding-top: 3px !important;
}

.input-fields-official ant-table-tbody>tr.ant-table-row-selected>* {
  background: none ;
}

.input-fields-official .ant-table-tbody>tr.ant-table-row-selected>td {
  background: none;
}

.input-fields-official .ant-table-tbody>tr.ant-table-row-selected:hover>td {
  background: #fafafa;
}

.input-field-items{
  width: 94.5%;
}

.input-fields-official td.ant-table-cell {
  z-index: 0;
}

.official-identifier-radio-group {
  margin-left: 32px;
}

.official-identifier-radio-group label {
  color: #272728;
}

.official-identifier-radio-group .radio-group {
  padding-bottom: 10px
}