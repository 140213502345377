#grouping-badge-published {
  font-size: 12px;
  border-radius: 100px;
  background: #e5f7de;
  width: 72px;
  height: 21px;
  border: 1px solid #3e8d21;
  box-sizing: border-box;
  padding: 1px 7px 1px 7px;
  color: #3e8d21;
  justify-content: center;
  align-items: center;
  height: 18px;
  font-weight: 600;
  line-height: 18px;
  display: flex;
  margin-top: 2px;
}

#grouping-badge-unpublished {
  font-size: 12px;
  border-radius: 100px;
  background: #e6f9ff;
  width: 88px;
  height: 21px;
  border: 1px solid #007da3;
  box-sizing: border-box;
  padding: 1px 7px 1px 7px;
  color: #005a75;
  justify-content: center;
  align-items: center;
  height: 18px;
  font-weight: 600;
  line-height: 18px;
  display: flex;
  margin-top: 2px;
}

#grouping-badge-draft {
  font-size: 12px;
  border-radius: 100px;
  background: #f1f4f4;
  width: 46px;
  height: 21px;
  border: 1px solid #677274;
  box-sizing: border-box;
  padding: 1px 7px 1px 7px;
  color: #4d5557;
  justify-content: center;
  align-items: center;
  height: 18px;
  font-weight: 600;
  line-height: 18px;
  display: flex;
  margin-top: 2px;
}

#grouping-badge-test {
  font-size: 12px;
  border-radius: 100px;
  background: #feefe7;
  width: 46px;
  height: 21px;
  border: 1px solid#C84C09;
  box-sizing: border-box;
  padding: 1px 7px 2px;
  color: #9c3b07;
  justify-content: center;
  align-items: center;
  height: 18px;
  font-weight: 600;
  line-height: 18px;
  display: flex;
  margin-top: 2px;
}

.ant-table-thead > tr > th {
  background: #ebebeb;
  font-size: 14px;
  font-weight: 700;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
  display: none;
}

.ant-table table {
  font-size: 16px;
}

.ant-menu-item.ant-menu-item-only-child.custom-menu-item {
  margin-top: 0;
  margin-bottom: 0;
}

.ant-menu-item-selected.custom-menu-title {
  background-color: #3c7cde;
}

.bi-three-dots-vertical.bi {
  height: 30px;
}

.ant-table-cell {
  padding: 12px 16px 12px 6px !important;
}

.ant-dropdown-menu-title-content {
  display: flex;
  justify-content: center;
}

.ant-dropdown.custom-action-dropdown {
  width: 88px;
}

.ant-dropdown-menu-item {
  font-size: 13px;
  font-weight: 400;
  padding: 1px;
}

tr.ant-table-expanded-row > td {
  background-color: white;
  border-bottom: 0px !important;
}

.expanded-cell {
  margin-left: 4.7rem;
  margin-bottom: 35px;
  border-bottom-width: 6px;
}
tr.ant-table-expanded-row.ant-table-expanded-row-level-1 > td {
  padding-right: 0px !important;
}

.ant-table-tbody > tr > td {
  border-top: 1px solid #f0f0f0;
  border-bottom: 0;
  transition: background 0.3s;
}

.bi-three-dots-vertical {
  cursor: pointer;
  margin-right: 12px;
}
