@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

/*
 * We'll use CSS grids for the base template
 * More info on https://cssgrid.io/ by @wesbos
 */
#main {
  display: grid;
  grid-template-columns: 6rem auto;
}

@media (min-width: 1200px) {
  #main {
    grid-template-columns: 12rem auto;
  }
}

a {
  text-decoration: none;
  /* Or @apply .no-underline; */
}

/* Just a new padding size */
.pt-16 {
  padding-top: 4rem;
}

/*
   * Just some customized new utilities needed for the submenus
   * Add this before
   */
.top-full {
  top: 100%;
}
.left-full {
  left: 100%;
}

/*
   * This hacks is just needed in Codepen because the previous rules are prioritary to xl:pin-none.
   * In your own config this should not be needed.
   */

@media (min-width: 1200px) {
  .xl\:pin-none {
    left: auto;
  }
}

/* 
   * Active "group-hover" for the "display" module in your
   * tailwind.js config file like this:
   * 
   * display: ['responsive', 'group-hover']
   *
   * More info on https://tailwindcss.com/docs/state-variants/#group-hover
   */
.group:hover .group-hover\:block {
  display: block;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.gap-6 {
  gap: 1.5rem;
}

/* css for Alert/Toast backround color and border */

.Toastify__toast--error {
  background: #fdedef !important;
  border-left: 4px solid rgb(230, 60, 60);
  border-color: #bf0023;
  border-radius: 0;
}

.Toastify__toast--success {
  background: #d9fcf2 !important;
  border-left: 4px solid rgb(53, 182, 53);
  border-color: #0dab7b;
  border-radius: 0;
}

.Toastify__toast-body {
  color: black;
}

.Toastify {
  margin-bottom: -2px;
}

.Toastify__toast.Toastify__toast-theme--light {
  border-radius: 0px;
}
