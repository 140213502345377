.text-small-custom-2 > .anticon svg {
  width: 13.33px;
  height: 9.33px;
  color: #677274;
}

.table-header .ant-table-thead tr th {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #222222;
  background: #e1e1e1;
}
